export const UPDATE_TYPE_STOCK = "UPDATE_TYPE_STOCK";
export const UPDATE_TYPE_PRODUCT = "UPDATE_TYPE_PRODUCT";
export const UPDATE_TYPE_CHANNEL = "UPDATE_TYPE_CHANNEL";
export const UPDATE_TYPE_WAREHOUSE = "UPDATE_TYPE_WAREHOUSE";
export const UPDATE_TYPE_COMBODATA = "UPDATE_TYPE_COMBODATA"
export const UPDATE_SHIPPING_PARTNER_WAREHOUSE = "UPDATE_SHIPPING_PARTNER_WAREHOUSE";
export const UPDATE_TYPE_VARIATION = "UPDATE_TYPE_VARIATION";

//path constants
export const DASHBOARD_BASE_PATH="/dashboard"
export const MANAGE_PRODUCT_PATH="/manage_products";
export const ADD_PRODUCT_PATH="/add_product2";
export const MANAGE_STOCK_PATH="/manage_stock";
export const SHIPPING_PARTNER_DASHBOARD="/shipping_partner";
export const PROFILE = "/update_profile";
export const JOB_MANAGEMENT = "/job_batch_management_dashboard";
export const MANAGE_VARIATION_PATH="/manage_variations"
export const UPDATE_REMOVE_VARIATION_PATH="/update_remove_variation"
export const CHANNEL_ORDER_PATH="/channel_orders"
export const STOCK_HISTORY_PATH="/stock_history_reports"
export const INVOICE_PATH = "/invoice"
//
export const ITHINK_LOGISTICS="ITHINK_LOGISTICS";
