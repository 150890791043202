import { configureStore } from "@reduxjs/toolkit";
import userReducer from './userSlice'
import loaderReducer from './loader'
import warehouseReducer from './warehouseSlice'
import updateDataReducer from './updateData'
import { warehouseApi } from "../hooks/RTK_Service/warehouse";
import { comboDataApi } from "../hooks/RTK_Service/comboData";
import { userLoginRegisterApi } from "../hooks/RTK_Service/userLoginRegister";
import { marketplaceApi } from "../hooks/RTK_Service/marketplaceApi";
import { ordersApi } from "../hooks/RTK_Service/ordersApi";
import { productApi } from "../hooks/RTK_Service/productApi";
import { jobBatchApi } from "../hooks/RTK_Service/jobBatchApi";
import { stockApi } from "../hooks/RTK_Service/stockApi";
import { channelApi } from "../hooks/RTK_Service/channel";
import { shippingPartnerApi } from "../hooks/RTK_Service/shippingPartnerApi";
import { productVariationsApi } from "../hooks/RTK_Service/productVariationsApi";
import { channelOrdersApi } from "../hooks/RTK_Service/channelOrders";
import { mapUnmapProductDataApi } from "../hooks/RTK_Service/MapUnmapProductData";
import { stockHistoryApi } from "../hooks/RTK_Service/stockHistory";


const store = configureStore({
    reducer:{
        user:userReducer,
        loader:loaderReducer,
        warehouse:warehouseReducer,
        updateData:updateDataReducer,
        warehouseApi : warehouseApi.reducer,
        comboDataApi : comboDataApi.reducer,
        userLoginRegisterApi : userLoginRegisterApi.reducer,
        marketplaceApi : marketplaceApi.reducer,
        ordersApi : ordersApi.reducer,
        productApi : productApi.reducer,
        jobBatchApi : jobBatchApi.reducer,
        stockApi :stockApi.reducer,
        channelApi : channelApi.reducer,
        shippingPartnerApi: shippingPartnerApi.reducer,
        productVariationsApi: productVariationsApi.reducer,
        channelOrdersApi:channelOrdersApi.reducer,
        mapUnmapProductDataApi:mapUnmapProductDataApi.reducer,
        stockHistoryApi:stockHistoryApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat([
            warehouseApi.middleware, 
            comboDataApi.middleware,
            userLoginRegisterApi.middleware,
            marketplaceApi.middleware,
            ordersApi.middleware,
            productApi.middleware,
            jobBatchApi.middleware,
            stockApi.middleware,
            channelApi.middleware,
            shippingPartnerApi.middleware,
            productVariationsApi.middleware,
            channelOrdersApi.middleware,
            mapUnmapProductDataApi.middleware,
            stockHistoryApi.middleware,
        ]),
});

export default store;