import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Orders from './Orders';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useGetAllWarehousesQuery } from '../../../hooks/RTK_Service/warehouse';
import { apiErrorManagemnt2 } from '../../../api/Custom';
import { DASHBOARD_BASE_PATH, INVOICE_PATH } from '../../../custome/constants';
import { useNavigate } from 'react-router-dom';
import { useUpdateMarketplaceOrderStatusMutation } from '../../../hooks/RTK_Service/ordersApi';
import Swal from 'sweetalert2';
import BtnLodderIcon from '../../../components/custom/BtnLodderIcon';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}




export default function MarketplaceOrdersBasicTab() {
    const [value, setValue] = useState(0);
    const [orderDetails, setOrderDetails] = useState(null);
    var warehouses = [];
    const [warehouseId, setWarehouseId] =useState(-1);
    const navigate = useNavigate();
    const [updateOrderproductStatus, updateOpRes] = useUpdateMarketplaceOrderStatusMutation();

    const handleChangeWarehouseId=(id)=>{
        setWarehouseId(id);
    }

    const generateInvoice = async () => {
        if (orderDetails?.status !== 'Packaging' && orderDetails?.status !== 'Shipped' && orderDetails?.status !== 'Cancelled') {
            updateOrderproductStatus({ status: 'Packaging', orderProductId: orderDetails?.orderProductId, orderId: orderDetails?.marketplaceOrder?.orderId });
        }
        navigate(DASHBOARD_BASE_PATH + INVOICE_PATH, { state: orderDetails })
    };

    const markAsShipped = async () => {
        Swal.fire({
            title: "Are you sure?",
            text: "Convert order status to shipped.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.isConfirmed) {
                updateOrderproductStatus({ status: 'Shipped', orderProductId: orderDetails?.orderProductId, orderId: orderDetails?.marketplaceOrder?.orderId }).then((res) => {
                    if (res?.data?.status == '0') {
                        Swal.fire("Success", "Status changed into shipped.", "success");
                    }
                })

            }
        });
    };


    //we assume one user have maximum 100 of warehouse
    const warehouse = useGetAllWarehousesQuery({ page: 1, limit: 100 });

    if (warehouse.isError) {
        apiErrorManagemnt2(warehouse.error);
    }

    if (warehouse.isSuccess) {
        // console.log(warehouse.data.data);
        warehouses = warehouse.data.data.content;
    }

    const totalPriceOfOrderProducts = (products) => {
        var total = 0;
        products?.map((p) => {
            total += p?.solePrice;
        })
        return total;
    }
    const handelChangeOrderDetails = (data) => {
        //console.log(data)
        setOrderDetails(data);
    }

    // const handelChangeOrderDetails = useMemo((data)=>{
    //     console.log(data)
    // },orderDetails?.orderId);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className="col-12 col-md-7">
                    <Box sx={{ width: '100%' }}>

                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="OPEN" {...a11yProps(0)} />
                                <Tab label="PACKAGING" {...a11yProps(1)} />
                                <Tab label="CANCELLED" {...a11yProps(2)} />
                                <Tab label="SHIPPED" {...a11yProps(3)} />
                                <Tab label="ALL" {...a11yProps(4)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <Orders type={"processing"} warehouses={warehouses} warehouseId={warehouseId} handleChangeWarehouseId={handleChangeWarehouseId} handelChangeOrderDetails={handelChangeOrderDetails} totalPriceOfOrderProducts={totalPriceOfOrderProducts} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <Orders type={"Packaging"} warehouses={warehouses} warehouseId={warehouseId} handleChangeWarehouseId={handleChangeWarehouseId} handelChangeOrderDetails={handelChangeOrderDetails} totalPriceOfOrderProducts={totalPriceOfOrderProducts} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <Orders type={"Cancelled"} warehouses={warehouses} warehouseId={warehouseId} handleChangeWarehouseId={handleChangeWarehouseId} handelChangeOrderDetails={handelChangeOrderDetails} totalPriceOfOrderProducts={totalPriceOfOrderProducts} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}>
                            <Orders type={"Shipped"} warehouses={warehouses} warehouseId={warehouseId} handleChangeWarehouseId={handleChangeWarehouseId} handelChangeOrderDetails={handelChangeOrderDetails} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={4}>
                            <Orders type={"All"} warehouses={warehouses} warehouseId={warehouseId} handleChangeWarehouseId={handleChangeWarehouseId} handelChangeOrderDetails={handelChangeOrderDetails} />
                        </CustomTabPanel>
                    </Box>
                </div>
                <div className="col-12 col-md-5">
                    {
                        orderDetails != null ?

                            <>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs>
                                        <Tab label={orderDetails?.marketplaceOrder?.orderId} />
                                    </Tabs>
                                </Box>
                                <Box sx={{ width: '100%', marginTop: "10px", borderColor: 'divider', height: "88vh", overflow: "auto", overflowX: "hidden" }} className="container-fluid">
                                    <h6 className='text-muted'><b>SUMMARY </b></h6>
                                    <TableContainer component={Paper} >
                                        <Table md={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Product</TableCell>
                                                    <TableCell align="right">Qty</TableCell>
                                                    <TableCell align="right">Unit price</TableCell>
                                                    <TableCell align="right">Total</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell component="th" scope="row">
                                                            <span><b>Title :</b> {orderDetails?.productName}</span><br />
                                                            <span><b>SKU : {orderDetails?.sku}</b></span><br />
                                                            <span><b>Warehouse Id : {orderDetails?.warehouseId}</b></span>
                                                        </TableCell>
                                                        <TableCell align="right">{orderDetails?.quantity}</TableCell>
                                                        <TableCell align="right">{orderDetails?.solePrice}</TableCell>
                                                        <TableCell align="right">{orderDetails?.quantity * orderDetails?.solePrice}</TableCell>
                                                    </TableRow>
                                                }



                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell colSpan={3} align="right">Sub Total</TableCell>
                                                    <TableCell align="right">{orderDetails?.quantity * orderDetails?.solePrice}</TableCell>
                                                </TableRow>

                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell colSpan={2} align="right">Shipping charges(+)</TableCell>
                                                    <TableCell colSpan={2} align="right">0</TableCell>
                                                </TableRow>

                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell colSpan={3} align="right">Total Tax</TableCell>
                                                    <TableCell align="right">{orderDetails.taxAmt}</TableCell>
                                                </TableRow>

                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell colSpan={3} align="right">Total</TableCell>
                                                    <TableCell align="right">{(orderDetails?.quantity * orderDetails?.solePrice) + (orderDetails.taxAmt)}</TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                        <div className='container-fluid mt-4'>
                                            <div className='row'>
                                                <div className="col-12 col-md-4">
                                                    <h6><b>Order Product Id </b></h6>
                                                </div>
                                                <div className="col-12 col-md-8">
                                                    <small><b>{orderDetails?.orderProductId}</b></small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='container-fluid mt-4'>
                                            <div className='row'>
                                                <div className="col-12 col-md-4">
                                                    <h6><b>Order Recived On </b></h6>
                                                </div>
                                                <div className="col-12 col-md-8">
                                                    <h6>{new Date(orderDetails?.marketplaceOrder?.orderDtTime).toLocaleString()}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='container-fluid mt-4'>
                                            <div className='row'>
                                                <div className="col-12 col-md-4">
                                                    <h6><b>Seller </b></h6>
                                                </div>
                                                <div className="col-12 col-md-8">
                                                    <span><b>Name :</b> </span><br />
                                                    <span><b>Email :</b> {orderDetails?.marketplaceOrder?.sellerId}</span><br />
                                                    <span><b>Phone:</b> +91 </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='container-fluid mt-4'>
                                            <div className='row'>
                                                <div className="col-12 col-md-4">
                                                    <h6><b>Customer Address </b></h6>
                                                </div>
                                                <div className="col-12 col-md-8">
                                                    <span>{orderDetails?.marketplaceOrder?.orderAddress}, </span><br />
                                                    <span>{orderDetails?.marketplaceOrder?.state},</span><br />
                                                    <span>IN, India</span><br />
                                                    <span>{orderDetails?.marketplaceOrder?.pincode}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='container-fluid mt-4'>
                                            <div className='row'>
                                                <div className="col-12 col-md-4">
                                                    <h6><b>Customer Details </b></h6>
                                                </div>
                                                <div className="col-12 col-md-8">
                                                    <span><b>Name : </b>{orderDetails?.marketplaceOrder?.buyerName}</span><br />
                                                    <span><b>Email : </b>{orderDetails?.marketplaceOrder?.buyerEmail}</span><br />
                                                    <span><b>Phone: </b>{orderDetails?.marketplaceOrder?.buyerContact}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='container-fluid mt-4'>
                                            <div className='row'>
                                                <div className="col-12 col-md-4">
                                                    <h6><b>Financial Status </b></h6>
                                                </div>
                                                <div className="col-12 col-md-8">
                                                    <span><b>{orderDetails?.marketplaceOrder?.paymentStatus}</b></span><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='container-fluid mt-4'>
                                            <div className='row'>
                                                <div className="col-12 col-md-4">
                                                    <h6><b>Invoice Number </b></h6>
                                                </div>
                                                <div className="col-12 col-md-8">

                                                    <Button
                                                        variant="contained"
                                                        sx={{ mt: 1, mr: 1 }}
                                                        onClick={() => { generateInvoice() }}

                                                    >
                                                        {
                                                            orderDetails?.status !== 'Packaging' && orderDetails?.status !== 'Shipped' && orderDetails?.status !== 'Cancelled' ?
                                                                <><BtnLodderIcon action={updateOpRes?.isLoading} /> Generate Invoice</>
                                                                :
                                                                <>View Invoice</>
                                                        }
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='container-fluid mt-4'>
                                            <div className='row'>
                                                <div className="col-12 col-md-4">
                                                    <h6><b>Shipping Status </b></h6>
                                                </div>
                                                <div className="col-12 col-md-8">

                                                    <Button
                                                        variant="contained"
                                                        sx={{ mt: 1, mr: 1 }}
                                                        onClick={()=>{Swal.fire("Not Implemented!!","Shipping partner api not implemented yet to your warehouse.","info")}}
                                                    >
                                                        Ship Now
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        sx={{ mt: 1, mr: 1 }}
                                                        onClick={() => { markAsShipped() }}
                                                    >
                                                        <BtnLodderIcon action={updateOpRes?.isLoading} /> Mark As Shipped
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='container-fluid mt-5 mb-5'>
                                            <div className='row'>
                                                <div className="col-12 col-md-4">
                                                </div>
                                                <div className="col-12 col-md-8">

                                                </div>
                                            </div>
                                        </div>

                                    </TableContainer>

                                </Box>
                            </>
                            :
                            <>
                                <div className='alert alert-muted text-center'>Order not selected !!</div>
                            </>
                    }
                </div>
            </div>
        </div >
    );
}