import React from 'react'
import logo from '../../files/imgs/manageEcom-logo.png'
import Avatar from '@mui/material/Avatar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import TelegramIcon from '@mui/icons-material/Telegram';
import LanguageIcon from '@mui/icons-material/Language';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';

import './footer.css'
import { Divider, Typography } from '@mui/material';
import { LineAxis } from '@mui/icons-material';
import { Link } from 'react-router-dom';
export default function Footer() {
    return (
        <div className='footer-section'>
            <div className="container">
                <div className="row py-5">

                    <div className="col-12 col-sm-4 my-4">
                        <div>
                            <img className='footer-logo' src={logo} alt="manageEcom.com" />
                            <div className='footer-divider'></div>

                        </div>
                    </div>
                    <div className="col-12 col-sm-4 my-4">
                        <Typography sx={{ color: 'var(--mainColor2)', margin: '0 0 12px 0' }} component="h1" variant="h5">
                            SITE MAP
                        </Typography>
                        <Link to='/' className='link'><Typography component="h1" style={{ fontSize: '15px' }}>
                            Home
                        </Typography></Link>
                        <Link to='/about' className='link'><Typography component="h1" style={{ fontSize: '15px' }}>
                            About
                        </Typography></Link>
                        
                        <Link to='/service' className='link'><Typography component="h1" style={{ fontSize: '15px' }}>
                            Services
                        </Typography></Link>
                    </div>
                    <div className="col-12 col-sm-4 my-4">
                        <div className="sec3-info">

                            <div className='d-flex'>
                                <LocationOnIcon sx={{ color: 'gray' }} />&nbsp;
                                <p className='text-muted'>Nuvio Technologies Pvt Ltd.
                                    Shop No. 2, Shakti Dham CHS, Plot No. 125, Sector - 21, Ghansoli, Navi Mumbai - 400701, Maharashtra, India
                                </p>
                            </div>
                            <div className='d-flex mt-1'>
                                <LocalPhoneIcon sx={{ color: 'gray' }} />&nbsp;
                                <p className='text-muted'>
                                    +91 7718895734
                                </p>
                            </div>
                            <div className='d-flex mt-1'>
                                <MailOutlineIcon sx={{ color: 'gray' }} />&nbsp;
                                <p className='text-muted'>
                                    <a href="mailto:support@nuvio.in">support@nuvio.in</a>
                                </p>
                            </div>
                            <div className='d-flex mt-1'>
                                <a className='text-light' href="https://api.whatsapp.com/send/?phone=917718895734" >
                                    <Avatar sx={{ border: '1px solid white', mr: 1, bgcolor: 'green' }}>
                                        <WhatsAppIcon />
                                    </Avatar>
                                </a>
                                <Avatar sx={{ border: '1px solid white', mr: 1, bgcolor: 'var(--mainColor2)' }}>
                                    <FacebookIcon />
                                </Avatar>
                                <Avatar sx={{ border: '1px solid white', mr: 1, bgcolor: 'var(--mainColor)' }}>
                                    <LanguageIcon />
                                </Avatar>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="divider"></div>
            <div className="copyright-section">

                <Typography sx={{ color: 'gray', fontSize: '15px' }} component="h2">
                    Copyright © 2023 Nuvio Technologies Pvt Ltd. All Rights Reserved.
                </Typography>
            </div>
        </div>
    )
}
